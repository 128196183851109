import { useRef } from "react";
import { useEffect } from "react";
//import { useState } from "react";
import arrow_right_white from "../../assets/img/svg/arrow_right_white.svg";
import { updateTimestamps, updateUndefinedPhoto } from "../../services/fbAuth";
import CopyStatusButton from "./CopyStatusButton";

function WebViewRedirection({ is_IOS, is_third_party_wv /* setWebView */ }) {
  const from_source = window.location.host + window.location.pathname;
  const render_count = useRef(0);

  /* useEffect(() => {
    if (render_count.current === 0 && !is_IOS) {
      if (!is_third_party_wv)
        window.location.replace(
          `intent://${from_source}#Intent;scheme=http;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;end;`
        );
      else window.location.replace("https://" + from_source);
      render_count.current = render_count.current + 1;
      //setWebView(false);
    }
  }); */
  //console.log(is_IOS, is_third_party_wv);
  return (
    <div className="full-screen flex-column flex-align-center flex-justify-center">
      <h3
        style={{ lineHeight: "1.5rem" }}
        className="fw-500 fs-1-2 text-align-center"
      >
        Continue to this Giveaway <br />
        in Mobile browser.
      </h3>
      <br />
      {is_IOS ? (
        <>
          <p>
            Please go to the iOS menu and select <br /> Open in Browser/Safari
          </p>{" "}
        </>
      ) : is_third_party_wv ? (
        <button className="btn-large btn-accent">
          <a
            className="flex-row flex-align-center flex-justify-center"
            href={"https://" + from_source}
            target="_blank"
            rel="noopener noreferrer"
          >
            Continue &nbsp; &nbsp;{" "}
            <img src={arrow_right_white} alt="continue" />
          </a>
        </button>
      ) : (
        <button className="btn-large btn-accent">
          <a
            className="flex-row flex-align-center flex-justify-center"
            href={`intent://${from_source}#Intent;scheme=http;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;end;`}
          >
            Continue &nbsp; &nbsp;{" "}
            <img src={arrow_right_white} alt="continue" />
          </a>
        </button>
      )}
    </div>
  );
}

export default WebViewRedirection;
