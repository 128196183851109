import React from "react";
import { redirect, useNavigate } from "react-router-dom";
import { dashboard_routes, index_routes } from "../../../routes";

export const myErrorHandler = (error, info) => {
  // Do something with the error
  // E.g. log to an error logging client here
  info.componentStack.replaceAll("@", "\n Path: ");
  const [empty, node, ...tree] = info.componentStack
    .replaceAll("@", "\nPath: ")
    .split(/\r?\n/);
  const [root, ...details] = [...tree];

  console.log(
    "%c\n\nDetailed Error Component Stack is : \n" +
      details.map((item) => {
        return item + "\n";
      }),
    "color: #00B8D4;"
  );

  console.log(
    "%cError Message : " + error.message,
    "background: #4d4d4d; color: #EA80FC; font-weight: bold; font-size: 15px;"
  );

  console.log(
    "%cIn component: " + empty + node + "\n" + root,
    "background: #4d4d4d; color: #EA80FC; font-weight: bold; font-size: 15px;"
  );
};

function ErrorFallback(props) {
  const navigate = useNavigate();
  return (
    <div className="Error-fallback">
      <div>
        <p>
          Whoops! <br />
          Something went wrong
        </p>
        <pre>Lost in Space</pre>

        <pre>
          Nothing to see here. <br />
          Refresh the page or <br />
          Let's go somewhere meaningful.
        </pre>
        <button
          className="btn-large btn-accent"
          onClick={() =>
            navigate(
              "../../" + index_routes.dashboard + "/" + dashboard_routes.home,
              { replace: true }
            )
          }
        >
          Go to home
        </button>
      </div>
    </div>
  );
}

export default ErrorFallback;
