import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { platforms } from "../meta";
import { auth, db } from "./fb";
import { __timestamps_schema } from "./schema/timestampSchema";

const authfb = auth;

/* const testingIds = [
  "oMBGv5tg8as3YmV0BnOj",
  "ePa1hlGfNl6NevmevYCW",
  "BlCU9VN8WzMq7RInRn4D",
  "F6jPVqC5aiobvz7yp5vB",
  "KjspYLgcdTvWQdP0IQJF",
  "LTABkvaJGdH4CiEXglk9",
  "BFBDz07xxkEpEEuOkUkg",
  "e6J23N5WuNWO3uvLWf4w",
  "TAxFL5K9vwKZsUUMvqZ1",
  "QKSo2bf465EATOmSefbf",
];

const testingIds2 = [
  "2jKn5gfyXjp8OV4jpW0B",
  "zSwxOsa4CUzVYHK6QAsi",
  "2vFeQtDLxAnjFJGjfO8D",
  "Z1ny4O3lTiaAOAjn12Ti",
  "c3vRMjK82O7hp43oUsY4",
  "zxAfw0b0OJSqoRek0u8m",
  "HzixAPEZEeTAq12kMAYQ",
  "a5vcURSUveOvhPZaaBzP",
  "rch8dVFk0KRVGJQpi4Or",
  "P9B41B4JtA3yf3ghLd2c",
];

const testingIds3 = [
  "hVmUPaFvifJsarot7WyL",
  "sykp5arzRrIPtvqIiPdn",
  "PDnfBlIsbz8dqc6c8L8s",
  "MswtoDLTP4FHt3vRyw7b",
  "9UQ3KnlxokbJpPb0uk6F",
  "V6TWKJacveEndse5fLoz",
  "D7DhpViuU0KyoAtRfEQu",
  "xOBUHW3iJ23niTNK9kuB",
  "9dNspK0HdzM86bxG6FkM",
  "NP31mQpAzX7GqtosSbuI",
];

const testingIds4 = [
  "23vcKUmQREM5RdYDB0xE",
  "9zjZSvXaJXHmz629cr9o",
  "k3RwuthQY1ZAUoZvIX0r",
  "m9CwnbHYLU5sOumtpPwB",
  "EVRntLWYPHawNqEGGCuV",
  "PfPaiJOIfA14utIR9wOf",
  "1XCUtDi2T5EbFzaVEDME",
  "gLgbExdfr3fRn7tnu1kN",
  "eIoabRCyQ28s4MaLEcny",
  "yz3de0jBxGDn6LWsqZNa",
];

const testingIds5 = [
  "1UamWddrP9sHeAGSDBdz",
  "or3Tyq9pCC8ccSdxX8JE",
  "ddbIMNTOB8OqktUviLzd",
  "JW2PDwnv5D0t8R8OKE5T",
  "izRhZXNXvZ14p8tAlNTI",
  "PvnLuqFNEfe8qPD7XZ6u",
  "PlbdS0fn1GDHgpZDe727",
  "c0m0gdEE0Kan0gdCAJOA",
  "aw9phkS377TevWqCmxkw",
  "zYkLVrDJrkTtn80gvoN9",
];

const testingIds6 = [
  "39kRvXftycxMDbr4j4vQ",
  "fjXAp7zMu6g0xKO1rpwd",
  "jiqcm7im0ZL22g6suTi4",
  "rJJDw7TDiJMjl5T74Ia4",
  "rJCKyi5Klnt7Svab3DaC",
  "U0drGeSMPNmizK4LJBOm",
  "2lkt8hoXRKmFjyQO0b6r",
  "d4qyf0hKwN6MCdgteu1q",
]; */

/* export const updateTestingIds = async () => {
  const testingquery = query(
    collection(db, "end_users"),
    where("id", "in", testingIds6)
  );
  const docSnaps = await getDocs(testingquery);

  docSnaps.forEach(async (userDoc) => {
    await updateDoc(doc(db, "end_users", userDoc.data().id), {
      is_test_account: true,
    });
  });
}; */

export const updateTimestamps = async () => {
  //create an array of all docs which have a timestamp.
  //get influencers where id is not in array of all docs
  //update docs of fetched influencers
  //repeat for all collections
  const collection_to_up = "end_users";

  const no_timestamp_q = query(
    collection(db, collection_to_up),
    where("id", "not-in", ["1"])
  );

  const invalid_docSnaps = await getDocs(no_timestamp_q);
  console.log(invalid_docSnaps.size);
  invalid_docSnaps.docs.forEach(async (docSnap) => {
    await updateDoc(doc(db, collection_to_up, docSnap.id), {
      timestamps: {
        created_on: 1682415745362,
        deleted_on: null,
        updated_on: null,
        blocked_on: null,
        last_active_on: null,
      },
    });
  });
};

function exportToCsv(Results) {
  var CsvString = "";
  Results.forEach(function (RowItem, RowIndex) {
    RowItem.forEach(function (ColItem, ColIndex) {
      CsvString += ColItem + ",";
    });
    CsvString += "\r\n";
  });
  CsvString = "data:application/csv," + encodeURIComponent(CsvString);
  var x = document.createElement("A");
  x.setAttribute("href", CsvString);
  x.setAttribute("download", "alias_photo_issue_2023_06_06.csv");
  document.body.appendChild(x);
  x.click();
}

export const updateUndefinedPhoto = async () => {
  const docQuery = query(
    collection(db, "aliases"),
    where(
      "photo",
      ">=",
      "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/profile_uploads%2Faliases%2Fundefined%2Falias_photo?alt=media"
    )
  );

  const docSnaps = await getDocs(docQuery);
  console.log(docSnaps.docs.length);
  let csv_array = [["alias_id", "influencer_id", "created_on"]];
  let processed = 0;
  docSnaps.docs.forEach((aliasDoc) => {
    const user = {
      id: aliasDoc.data().id,
      influencer: aliasDoc.data().influencer,
      timestamps: new Date(
        aliasDoc.data().timetamps?.created_on ??
          aliasDoc.data().timestamps?.created_on ??
          0
      ),
      admin_link:
        "https://socialpi-admin.web.app/influencers/" +
        aliasDoc.data().influencer,
    };
    csv_array.push([...Object.values(user)]);
    console.log(user);
    processed += 1;
    if (processed === docSnaps.docs.length) {
      console.log("done");
      exportToCsv([...csv_array]);
    }
  });
};

export const updateParticipationTaskDataForAll = async ({
  giveawayId,
  taskId,
  field,
  value,
}) => {
  const docsQuery = query(
    collection(db, "participation_data"),
    where("giveaway", "==", giveawayId),
    where("participation_status", "==", "incomplete")
  );
  const docSnaps = await getDocs(docsQuery);
  console.log(docSnaps.docs.length);
  docSnaps.docs.forEach(async (doc_to_update) => {
    const { participant, tasks } = doc_to_update.data();
    const docId = participant + "_" + giveawayId;

    console.log("Doc ID to update", docId);
    let new_tasks = [...tasks];
    //let task_to_update = new_tasks.filter((task) => task.id === taskId);
    const index = new_tasks.findIndex((task) => task.id === taskId);
    //console.log("old task", new_tasks[index]);
    //console.log("index of task to  update", index);

    new_tasks[index] = { ...new_tasks[index], [field]: value };

    //console.log("new task", new_tasks[index]);
    //console.log(new_tasks);
    /*  await updateDoc(doc(db, "participation_data", docId), {
      tasks: [...new_tasks],
    }); */
  });
};

export const updateTasks = async ({ giveawayId }) => {
  const docsQuery = query(
    collection(db, "participation_data"),
    where("giveaway", "==", giveawayId)
  );
  const docSnaps = await getDocs(docsQuery);

  const taskId = 1676632542461;
  const task = {
    link: "https://www.instagram.com/p/CowBb69p9qo/",
    image: "",
    actions: { like: 5, comment: 10, story: 10 },
    comment_text: "tag any two friends in comments",
    id: taskId,
    tickets: 25,
    type: "post_activity",
    platform: platforms.instagram,
  };

  docSnaps.docs.forEach(async (doc_to_update) => {
    const { participant } = doc_to_update.data();
    const docId = participant + "_" + giveawayId;

    console.log("Doc ID to update", docId);

    /* if (participant !== "S4iQ0mKIE9vKXtJPUcd1")
      await updateDoc(doc(db, "participation_data", docId), {
        total_tickets: 35,
        participation_status: "incomplete",
        tasks: arrayUnion({
          ...task,
          actions_pending: ["comment", "story", "like"],
          status: "not started",
          tickets_earned: 0,
          started_at: "",
          completed_at: "",
        }),
      }); */
  });

  /*  await updateDoc(doc(db, "giveaways", giveawayId), {
    [`tasks.instagram.post_activity.${taskId}`]: { ...task },
    total_tickets: 35,
  }); */
};

export const updateLastLogin = async ({ userId }) => {
  const timestamp = Date.now();
  await updateDoc(doc(db, "end_users", userId), { last_login_at: timestamp });
};

export const updateLastActive = async ({ userId }) => {
  const timestamp = Date.now();
  await updateDoc(doc(db, "end_users", userId), {
    [__timestamps_schema.last_active_on]: timestamp,
  });
};

export const initiateSignInWithEmail = ({ email, from_giveaway }) => {
  console.log(email);
  const settings = {
    url:
      "https://win.socialpi.ai/?from_giveaway=" +
      from_giveaway +
      `?email=` +
      email,
    handleCodeInApp: true,
  };
  sendSignInLinkToEmail(authfb, email, settings)
    .then(() => {
      console.log("Sent email");
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      //window.localStorage.setItem("emailForSignIn", email);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
      // ...
    });
};
