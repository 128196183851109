import { initializeApp, getApps, getApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  arrayRemove,
  arrayUnion,
  limit,
  runTransaction,
  increment,
  Timestamp,
  getCountFromServer,
  /* addDoc,*/
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
  signInWithPhoneNumber,
  inMemoryPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { codes } from "../meta";
import { redirect } from "react-router-dom";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = !getApps.length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

const signUpWithPhone = async (phoneNumber, setConfirmation, verifier) => {
  signInWithPhoneNumber(auth, phoneNumber, verifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code)
      //console.log(confirmationResult);
      setConfirmation(confirmationResult);
      return confirmationResult;
      // ...
    })
    .catch((error) => {
      console.error(error);
    });
};

export const setTimestamps = () => {
  return {
    created_on: Date.now(),
    deleted_on: null,
    updated_on: null,
    blocked_on: null,
    last_active_on: null,
  };
};

const isParticipantBlocked = async (id) => {
  const userSnap = await getDoc(doc(db, "end_users", id));
  return userSnap.data().is_blocked ?? false;
};

const signUpWithGoogle = async () => {
  googleProvider.setCustomParameters({
    prompt: "select_account",
  });
  setPersistence(auth, browserLocalPersistence).then(() => {
    //console.log("State is now persisted");
  });
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const { displayName, photoURL, email, uid, accessToken, refreshToken } =
      res.user;

    return {
      profile: {
        first_name: displayName.split(" ")[0],
        last_name: displayName.split(" ")[1] ?? "",
        photo: photoURL,
        email: email,
        is_email_verified: true,
        mobile: "",
        is_mobile_verified: false,
      },
      auth: { email: { uid: uid, id: email } },
    };
  } catch (err) {
    console.error("From fb.js, function: signUpWithGoogle", err.message);
    return { error: true };
  }
};

const doesUserExist = (provider, id) => {
  return new Promise(async (resolve) => {
    try {
      const authQuery = new query(
        collection(db, "users"),
        where(`auth.${provider}.id`, "==", id)
      );
      const userSnapshot = await getDocs(authQuery);
      if (userSnapshot.docs.length !== 0) {
        return resolve({
          exists: true,
          data: { ...userSnapshot.docs[0].data() },
        });
      } else return resolve({ exists: false, data: {} });
    } catch (err) {
      console.error("From fb.js, function doesUserExist", err.message);
    }
  });
};

const doesParticipantExist = (provider, id) => {
  return new Promise(async (resolve) => {
    try {
      const authQuery = new query(
        collection(db, "end_users"),
        where(`auth.${provider}.id`, "==", id)
      );
      const participantSnapshot = await getDocs(authQuery);
      if (participantSnapshot.docs.length !== 0) {
        return resolve({
          exists: true,
          data: { ...participantSnapshot.docs[0].data() },
        });
      } else return resolve({ exists: false, data: {} });
    } catch (err) {
      console.error("From fb.js, function doesParticipantExist", err.message);
    }
  });
};

const createParticipantWithEmail = (auth, profile) => {
  const participantRef = doc(collection(db, "users"));

  return new Promise(async (resolve) => {
    try {
      const result = await doesUserExist("email", auth.email.id);
      const user = result.data;
      if (result.exists) {
        const userDoc = {
          timestamps: setTimestamps(),
          id: user.id,
          auth: { ...user.auth },
          profile: { ...user.profile },
          connected_accounts: {},
        };
        await updateDoc(doc(db, "users", user.id), {
          type: { ...user.type, end_user: true },
        });
        await setDoc(doc(db, "end_users", user.id), {
          ...userDoc,
        });

        return resolve(user);
      } else {
        const userDoc = {
          timestamps: setTimestamps(),
          id: participantRef.id,
          auth: { ...auth },
          profile: { ...profile },
          connected_accounts: {},
        };
        await setDoc(doc(db, "users", participantRef.id), {
          ...userDoc,
          default_role: null,
          type: {
            end_user: true,
            influencer: false,
            manager: false,
            brand: false,
          },
        });
        await setDoc(doc(db, "end_users", participantRef.id), {
          ...userDoc,
        });

        return resolve({ ...userDoc });
      }
    } catch (err) {
      console.error(
        "From fb.js, function: createParticipantWithEmail while updating User doc types",
        err.message
      );
    }
  });
};

const createParticipantWithMobile = (auth, profile) => {
  const participantRef = doc(collection(db, "users"));
  return new Promise(async (resolve) => {
    try {
      const result = await doesUserExist("mobile", auth.mobile.id);
      const user = result.data;
      if (result.exists) {
        const userDoc = {
          timestamps: setTimestamps(),
          id: user.id,
          auth: { ...user.auth },
          profile: { ...user.profile },
          connected_accounts: {},
        };
        await updateDoc(doc(db, "users", user.id), {
          type: { ...user.type, end_user: true },
        });
        await setDoc(doc(db, "end_users", user.id), {
          ...userDoc,
        });

        return resolve(user);
      } else {
        const userDoc = {
          id: participantRef.id,
          timestamps: setTimestamps(),
          auth: { ...auth },
          profile: { ...profile },
          connected_accounts: {},
        };
        await setDoc(doc(db, "users", participantRef.id), {
          ...userDoc,
          default_role: null,
          type: {
            end_user: true,
            influencer: false,
            manager: false,
            brand: false,
          },
        });
        await setDoc(doc(db, "end_users", participantRef.id), {
          ...userDoc,
        });

        return resolve({ ...userDoc });
      }
    } catch (err) {
      console.error(
        "From fb.js, function: createParticipantWithMobile",
        err.message
      );
    }
  });
};

const updateParticipantProfile = async (id, profile) => {
  const isBlocked = await isParticipantBlocked(id);
  if (isBlocked === true) return { success: false, code: codes.blocked };
  else {
    //await updateDoc(doc(db, "users", id), { profile: profile });
    await updateDoc(doc(db, "end_users", id), { profile: profile });
    return { success: true, code: codes.update_success };
  }
};

export const updateProfileFields = async ({ userId, data }) => {
  const fields = Object.keys(data);
  let update_object = {};
  update_object = fields.map((field) => {
    return { ...update_object, [`profile.${field}`]: data[field] };
  });
  try {
    await updateDoc(doc(db, "users", userId), { ...update_object });
    await updateDoc(doc(db, "end_users", userId), { ...update_object });
    return { success: true, code: codes.update_success };
  } catch (err) {
    console.log(err.message);
    return { success: false, code: codes.update_failed, message: err.message };
  }
};

export const writeUserAddress = async ({ userId, address }) => {
  try {
    await updateDoc(doc(db, "end_users", userId), {
      [`profile.addresses.${address.address_tag}`]: {
        ...address,
      },
    });
    return { success: true, code: codes.write_success };
  } catch (err) {
    console.log(err.message);
    return { success: false, code: codes.write_failed, message: err.message };
  }
};

const fetchParticipant = (id) => {
  return new Promise(async (resolve) => {
    try {
      const participant = await getDoc(doc(db, "end_users", id));
      if (!participant.exists()) return resolve(false);
      return resolve({
        ...participant.data(),
        profile: {
          ...participant.data().profile,
          addresses: participant.data().profile.addresses ?? {},
        },
      });
    } catch (err) {
      console.err("From fb.js, function: fetchParticipant", err.message);
    }
  });
};

const fetchGiveawayRewards = (rewards) => {
  var current_rewards = {};
  var fetched = 0;
  return new Promise((resolve) => {
    if (rewards.length === 0) return resolve(current_rewards);
    rewards.forEach(async (reward) => {
      await getDoc(doc(db, "rewards", reward)).then((rewardDoc) => {
        current_rewards = {
          ...current_rewards,
          [rewardDoc.data().id]: { ...rewardDoc.data() },
        };
        fetched += 1;
        if (rewards.length === fetched) return resolve(current_rewards);
      });
    });
  });
};

const logGiveawayStatusUpdate = ({
  giveaway,
  log_message,
  from_status,
  to_status,
}) => {
  const timestamp = Timestamp.now().seconds * 1000;
  // updateDoc()
};

const fetchRunningGiveaways = () => {
  const now = Timestamp.now().seconds * 1000;
  const giveawayStatusQuery = new query(
    collection(db, "giveaways"),
    where("status", "==", "running")
  );
  var giveaways = {};
  return new Promise(async (resolve) => {
    try {
      var idsProcessed = 0;
      const giveawaySnapshot = await getDocs(giveawayStatusQuery);
      if (giveawaySnapshot.docs.length === 0) return resolve(giveaways);
      else {
        giveawaySnapshot.docs.forEach(async (snap) => {
          //if (snap.data().isTesting !== true) {
          const { id, rewards, end_date, end_time } = snap.data();
          var current_rewards = await fetchGiveawayRewards(rewards);
          const end_duration =
            snap.data().end_duration ??
            Date.parse(new Date(end_date + " " + end_time));
          if (end_duration < now) {
            //console.log("Forcefully ending");
            //console.log(now);
            //console.log(end_duration);
            await updateDoc(doc(db, "giveaways", id), { status: "ended" });
            /*    giveaways = {
              ...giveaways,
              [id]: {
                ...doc.data(),
                rewards: { ...current_rewards },
                status: "ended",
              },
            }; */
          } else {
            giveaways = {
              ...giveaways,
              [id]: { ...snap.data(), rewards: { ...current_rewards } },
            };
          }
          //}
          idsProcessed += 1;
          if (idsProcessed === giveawaySnapshot.docs.length)
            return resolve(giveaways);
        });
      }
    } catch (err) {
      console.error("From fb.js, function fetchRunningGiveaways", err.message);
    }
  });
};

const fetchBrandAds = async () => {
  const ad_module = await getDoc(doc(db, "ad_modules", "shhrTTeeQLlpX8IDIBfV"));
  return ad_module.data();
};

const fetchParticipatedGiveaways = (userId) => {
  let participation = {};
  let index = {};
  let ids_processed = 0;

  return new Promise(async (resolve) => {
    const participationQuery = query(
      collection(db, "participation_data"),
      where("participant", "==", userId)
    );
    const participatedSnapshot = await getDocs(participationQuery);
    if (participatedSnapshot.docs.length === 0) return resolve({});
    participatedSnapshot.docs.forEach(async (participated_giveaway) => {
      const giveaway = await getDoc(
        doc(db, "giveaways", participated_giveaway.data().giveaway)
      );
      if (giveaway.data().status !== "running") {
        index = { ...index, [giveaway.data().id]: { ...giveaway.data() } };
      }

      /* const giveaway_details = await getDoc(
        doc(db, "giveaways", participated_giveaway.data().giveaway)
      );
      const {
        banner_image,
        alias_name,
        alias_photo,
        description,
        title,
        start_date,
        start_time,
        end_date,
        end_time,
      } = giveaway_details.data(); */
      participation[participated_giveaway.data().giveaway] = {
        ...participated_giveaway.data(),
        /* banner_image: banner_image,
        alias_name: alias_name,
        alias_photo: alias_photo,
        description: description,
        title: title,
        start_date: start_date,
        start_time: start_time,
        end_date: end_date,
        end_time: end_time, */
      };
      ids_processed += 1;
      if (ids_processed === participatedSnapshot.docs.length)
        return resolve({ participated: participation, index: index });
    });
  });
  /* const participation_data = await getDoc(
      doc(db, "participation_data", userId)
    );
    let participation = { ...participation_data.data() };
    delete participation.giveaways;
    return resolve(participation);
  }); */
};

const initParticipation = (giveawayId, tasks, userId) => {
  const now = Date.now();

  return new Promise(async (resolve) => {
    // const isBlocked = await isParticipantBlocked(userId);
    //if (isBlocked) return resolve({ success: false, code: codes.blocked });

    try {
      const participation_data = {
        giveaway: giveawayId,
        participant: userId,
        participated_on: now,
        ad_views: 0,
        completed_on: "",
        tickets_earned: 0,
        is_winner: false,
        rewards_earned: [],
        sp_coins_earned: 0,
        rank: null,
        participation_status: "incomplete",
        tasks: tasks,
        platforms_completed: [],
        tasks_completed: [],
      };

      //console.log(participation_data);
      const findParticipation = await getDoc(
        doc(db, "participation_data", userId + "_" + giveawayId)
      );
      if (findParticipation.exists())
        return resolve({ [giveawayId]: findParticipation.data() });
      else {
        await setDoc(doc(db, "participation_data", `${userId}_${giveawayId}`), {
          ...participation_data,
        });

        try {
          await runTransaction(db, async (transaction) => {
            const docQuery = query(
              collection(db, "participation_data"),
              where("giveaway", "==", giveawayId)
            );
            const count = (await getCountFromServer(docQuery)).data().count;
            transaction.update(doc(db, "giveaways", giveawayId), {
              participants: count,
              ad_views: increment(3),
            });
          });
        } catch (err) {
          console.error(
            "From fb.js, function:initParticipation during transaction of updating giveaway document participants",
            err.message
          );
        }
      }
      return resolve({ [giveawayId]: participation_data });
    } catch (err) {
      console.log("From fb.js, function: initParticipation", err.message);
    }
  });
};

const updateParticipation = async (
  giveawayId,
  userId,
  participated_giveaway
) => {
  //const isBlocked = await isParticipantBlocked(userId);
  //if (isBlocked) return { success: false, code: codes.blocked };

  try {
    await updateDoc(doc(db, "participation_data", `${userId}_${giveawayId}`), {
      ...participated_giveaway,
    });
    return { success: true, code: codes.update_success };
  } catch (err) {
    console.log(err.message);
  }
};

export const updateAdViews = async ({ giveaway_id, count = 1 }) => {
  try {
    await runTransaction(db, async (transaction) => {
      transaction.update(doc(db, "giveaways", giveaway_id), {
        ad_views: increment(count),
      });
    });
    return { success: true, code: codes.update_success };
  } catch (err) {
    console.error(
      "From fb.js, function:updateParticipation during transaction of updating giveaway document participants",
      err.message
    );
  }
};

const completeParticipation = async (giveawayId, userId) => {
  //console.log("In completion firebase init");
  //const isBlocked = await isParticipantBlocked(userId);
  //if (isBlocked) return { success: false, code: codes.blocked };
  const givvyRef = doc(db, "giveaways", giveawayId);
  const participant_doc = await getDoc(
    doc(db, "participation_data", userId + "_" + giveawayId)
  );
  if (participant_doc.data().rank === null) {
    try {
      await runTransaction(db, async (transaction) => {
        const timestamp = Date.now();
        const giveawayDoc = await transaction.get(givvyRef);
        //Find cause for daa mismatch.
        const { completed_by } = giveawayDoc.data();
        const rank = completed_by + 1;
        if (completed_by === 0)
          transaction.update(givvyRef, {
            completed_first_by: userId,
            time_of_first_completion: timestamp,
          });
        transaction.update(givvyRef, { completed_by: increment(1) });
        transaction.update(
          doc(db, "participation_data", `${userId}_${giveawayId}`),
          {
            participation_status: "complete",
            rank: rank,
            completed_on: timestamp,
          }
        );
        return rank;
      });
    } catch (err) {
      console.log("In fb.js, in function completeParticipation", err.message);
    }
  } else return 0;
};

const fetchGiveaway = async (id) => {
  const givvySnap = await getDoc(doc(db, "giveaways", id));
  if (!givvySnap.exists()) return { [id]: false };
  else return { ...givvySnap.data() };
};

const fetchWinners = async (id) => {
  //console.log(id);

  const rewardQuery = query(
    collection(db, "rewards"),
    where("giveaway", "==", id)
  );
  const giveawayDoc = await getDoc(doc(db, "giveaways", id));
  const rewardSnaps = await getDocs(rewardQuery);

  return { giveaway: giveawayDoc.data(), rewards: rewardSnaps.docs };
};

const fetchGiveawaysWon = async (id) => {
  let giveaways = {};
  let ids_processed = 0;
  const winnerQuery = query(
    collection(db, "participation_data"),
    where("participant", "==", id),
    where("is_winner", "==", true)
  );

  return new Promise(async (resolve) => {
    const giveawaySnaps = await getDocs(winnerQuery);
    if (giveawaySnaps.docs.length === 0) return resolve(giveaways);
    giveawaySnaps.docs.forEach(async (givvy) => {
      const { giveaway } = givvy.data();
      const rewards = await fetchRewardsByGiveaway(giveaway);
      giveaways = {
        ...giveaways,
        [giveaway]: { ...givvy.data(), rewards: rewards },
      };
      ids_processed += 1;
      if (ids_processed === giveawaySnaps.docs.length)
        return resolve(giveaways);
    });
  });
};

export const fetchRewardsByGiveaway = (giveaway_id) => {
  const rewards = {};
  const rewardsRef = collection(db, "rewards");
  const reward_query = query(rewardsRef, where("giveaway", "==", giveaway_id));
  return new Promise(async (resolve, reject) => {
    let processed = 0;
    const reward_snaps = await getDocs(reward_query);
    reward_snaps.docs.forEach((snap) => {
      rewards[snap.data().id] = { ...snap.data() };
      processed += 1;
      if (processed === reward_snaps.docs.length) resolve(rewards);
    });
  });
};

const checkSocialAccountExists = async ({ platform, accountId, userId }) => {
  const connectQuery = query(
    collection(db, "end_users"),
    where(`connected_accounts[${platform}].accountId`, "==", accountId),
    where("id", "!=", userId)
  );
  const accountSnaps = await getDocs(connectQuery);
  if (accountSnaps.docs.length !== 0) return true;
  else return false;
};

const completeSocialAccountConnect = async ({ platform, userId, data }) => {
  await updateDoc(doc(db, "end_users", userId), {
    [`connected_accounts.${platform}`]: { ...data },
  });
};

const updateSocialAccountTokens = async ({ platform, userId, tokens }) => {
  const userDoc = await getDoc(doc(db, "end_users", userId));
  const { accountId, handle, username, detailed_item } =
    userDoc.data().connected_accounts[platform];
  //console.log(accountId, handle, username);
  await updateDoc(doc(db, "end_users", userId), {
    [`connected_accounts.${platform}`]: {
      ...tokens,
      accountId: accountId,
      handle: handle,
      username: username,
      detailed_item: detailed_item,
    },
  });
  return {
    accountId: accountId,
    handle: handle,
    username: username,
    detailed_item: detailed_item,
  };
};

export const updateAccountUsedForTask = async ({
  platform,
  userId,
  giveawayId,
  account,
}) => {
  console.log("In firebase account update in participation");
  const docId = userId + "_" + giveawayId;
  await updateDoc(doc(db, "participation_data", docId), {
    [`accounts_used.${platform}`]: {
      ...account,
    },
  });
};
/* const initParticipation = (giveawayId, tasks, userId) => {
  var participation_data = {};
  
  const now = Date.now();
  return new Promise(async (resolve) => {
    try {
      participation_data = {
        giveaway: giveawayId,
        participated_on: now,
        completed_on: "",
        tickets_earned: 0,
        is_winner: false,
        rewards_earned: [],
        sp_coins_earned: 0,
        rank: null,
        participation_status: "incomplete",
        tasks: [],
        platforms_completed: [],
        tasks_completed: [],
      };
      tasks.forEach((task) => {
        participation_data.tasks = [
          ...participation_data.tasks,
          {
            ...task,
            tickets_earned: 0,
            sp_coins_earned: 0,            
            actions_pending: [...Object.keys(task.actions)],
            account_used: {},
            comment_made: "",
            post_id: "",
            status: "not started",
            started_at: "",
            completed_at: "",
          },
        ];
      });
      await updateDoc(doc(db, "participation_data", userId), {
        giveaways: arrayUnion(giveawayId),
        [giveawayId]: { ...participation_data },
      });

      try {
        await runTransaction(db, async (transaction) => {
          transaction.update(doc(db, "giveaways", giveawayId), {
            participants: increment(1),
          });
        });
      } catch (err) {
        console.error(
          "From fb.js, function:initParticipation during transaction of updating giveaway document participants",
          err.message
        );
      }
      return resolve({ [giveawayId]: { ...participation_data } });
    } catch (err) {
      console.log("From fb.js, function: initParticipation", err.message);
    }
  });
};

const updateParticipation = async (
  giveawayId,
  userId,
  participated_giveaway
) => {
  await updateDoc(doc(db, "participation_data", userId), {
    [`${giveawayId}`]: { ...participated_giveaway },
  });
};
 */

const isAdVisible = async () => {
  const adDoc = await getDoc(doc(db, "meta", "meta_adx"));
  return adDoc.data().show_ads;
};
export {
  app,
  auth,
  db,
  signUpWithGoogle,
  doesParticipantExist,
  createParticipantWithEmail,
  createParticipantWithMobile,
  updateParticipantProfile,
  fetchParticipant,
  initParticipation,
  updateParticipation,
  fetchRunningGiveaways,
  fetchParticipatedGiveaways,
  completeParticipation,
  fetchBrandAds,
  signUpWithPhone,
  isParticipantBlocked,
  fetchGiveaway,
  fetchWinners,
  fetchGiveawaysWon,
  checkSocialAccountExists,
  completeSocialAccountConnect,
  updateSocialAccountTokens,
  isAdVisible,
};
