export const platforms = {
  youtube: "youtube",
  instagram: "instagram",
  twitter: "twitter",
  facebook: "facebook",
  telegram: "telegram",
  whatsapp: "whatsapp",
  dashboard: "dashboard",
  others: "others",
  custom: "custom",
};

export const giveaway_status = {
  drafts: "drafts",
  running: "running",
  scheduled: "scheduled",
  completed: "completed",
};

export const reward_types = {
  coupon: "coupon",
  product: "product",
  cash: "cash",
  voucher: "voucher",
  tickets: "tickets",
  custom: "custom",
};

export const reward_status = {
  not_generated: "pending winner generation",
  not_announced: "pending announcement",
  not_distributed: "pending distribution",
  distributed: "distributed",
};

export const defaults = {
  giveaways: {
    tasks: {
      twitter_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FTwitter.png?alt=media&token=12e09f97-80a8-427e-8cf9-62d99fae338b",
      instagram_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FInstagram.png?alt=media&token=d57e3d4e-88b3-4382-a257-2acb84e2337d",
      youtube_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FYoutube.png?alt=media&token=7ada93af-4867-4bd0-9d99-e0993cf6959c",
      telegram_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FTelegram.png?alt=media&token=cf9586b0-c03b-458a-81e3-6b39d1b60d06",
      facebook_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FFacebook.png?alt=media&token=a54c7b92-0121-484a-9a1b-8efd5860772f",
      custom_preview:
        "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2Fcustom.svg?alt=media&token=599dff63-8d75-4c8b-9603-5cc16c62f641",
    },
  },
};

export const task_fallbacks = {
  twitter:
    "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FTwitter.png?alt=media&token=12e09f97-80a8-427e-8cf9-62d99fae338b",
  instagram:
    "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FInstagram.png?alt=media&token=d57e3d4e-88b3-4382-a257-2acb84e2337d",
  youtube:
    "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FYoutube.png?alt=media&token=7ada93af-4867-4bd0-9d99-e0993cf6959c",
  telegram:
    "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FTelegram.png?alt=media&token=cf9586b0-c03b-458a-81e3-6b39d1b60d06",
  facebook:
    "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2FFacebook.png?alt=media&token=a54c7b92-0121-484a-9a1b-8efd5860772f",
  custom:
    "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/giveaway_presets%2Ftasks%2Fcustom.svg?alt=media&token=599dff63-8d75-4c8b-9603-5cc16c62f641",
};

export const env = process.env.NODE_ENV || "development";
export const callbackurls = {
  facebook: "",
  instagram: "",
  twitter:
    env === "development"
      ? process.env.REACT_APP_TEST_TWT_AUTH_CALLBACK_URL
      : process.env.REACT_APP_TWT_AUTH_CALLBACK_URL,
  youtube:
    env === "development"
      ? process.env.REACT_APP_TEST_YT_AUTH_CALLBACK_URL
      : process.env.REACT_APP_YT_AUTH_CALLBACK_URL,
};
export const api_url =
  env === "development"
    ? process.env.REACT_APP_TEST_SERVER_URL
    : process.env.REACT_APP_SERVER_URL;

export const api_endpoints = {
  socials: {
    twitter: "/api/twitter",
    facebook: "/api/facebook",
    instagram: "/api/instagram",
    youtube: "/api/youtube",
  },
  auth: {
    twitter: "/api/auth/twitter",
    facebook: "/api/auth/facebook",
    instagram: "/api/auth/instagram",
    youtube: "/api/auth/youtube",
  },
  khiladi: {
    base: "/api/brands/khiladiadda",
    get_khiladi_status: "/getKhiladiStatus",
  },
  msg91: {
    base: "/api/msg91",
    sendOTP: "/sendOTP",
    resendOTP: "/resendOTP",
    verifyOTP: "/verifyOTP",
  },
};

export const codes = {
  blocked: "blocked",
  update_success: 200,
  update_failed: 400,
  write_success: 201,
  write_failed: 401,
};

export const brand_codes = {
  khiladiadda: {
    givvy1: "KAGIVVY01",
  },
  gamerpe: { givvy1: "GPGIVVY02" },
};

export const ad_units = {
  root: "22847473922",
  banner_bottom: {
    ad_name: "win_banner_bottom_default",
    type: [320, 50],
    ad_unit_div: "div-gpt-ad-1670234785084-0",
  },
  banner_bottom_xs: {
    ad_name: "win_banner_bottom_xs",
    type: [300, 50],
    ad_unit_div: "div-gpt-ad-1679642491396-0",
  },

  banner_top: {
    ad_name: "win_banner_top_default",
    type: [320, 50],
    ad_unit_div: "div-gpt-ad-1687256850721-0",
  },
  banner_top_xs: {
    ad_name: "win_banner_top_xs",
    type: [300, 50],
    ad_unit_div: "div-gpt-ad-1679642326623-0",
  },

  banner_pc_left: {
    ad_name: "win_banner_pc_left_default",
    type: [160, 600],
    ad_unit_div: "div-gpt-ad-1670236363780-0",
  },
  banner_pc_right: {
    ad_name: "win_banner_pc_right_default",
    type: [160, 600],
    ad_unit_div: "div-gpt-ad-1670236461665-0",
  },

  rewards: {
    ad_name: "win_banner_rewards_default",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670236200882-0",
  },

  rewards_2: {
    ad_name: "win_banner_rewards_default_2",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1683033029404-0",
  },

  between_tasks: {
    ad_name: "win_banner_between_tasks_default",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670236236423-0",
  },
  between_tasks1: {
    ad_name: "win_banner_bw_task_default_1",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670324581306-0",
  },
  between_tasks2: {
    ad_name: "win_banner_bw_task_2",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670324756597-0",
  },
  between_tasks3: {
    ad_name: "win_banner_bw_task_3",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670327723046-0",
  },
  between_tasks4: {
    ad_name: "win_banner_bw_task_4",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670327753603-0",
  },
  between_tasks5: {
    ad_name: "win_banner_bw_task_5",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670327781765-0",
  },
  between_tasks6: {
    ad_name: "win_banner_bw_task_6",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670327807416-0",
  },
  between_tasks7: {
    ad_name: "win_banner_bw_task_7",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670325218682-0",
  },
  between_tasks8: {
    ad_name: "win_banner_bw_task_8",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670325177107-0",
  },
  between_tasks9: {
    ad_name: "win_banner_bw_task_9",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670325140296-0",
  },
  between_tasks10: {
    ad_name: "win_banner_bw_task_10",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670324938971-0",
  },
  between_tasks11: {
    ad_name: "win_banner_bw_task_11",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670325022619-0",
  },
  between_tasks12: {
    ad_name: "win_banner_bw_task_12",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670325055986-0",
  },
  between_tasks13: {
    ad_name: "win_banner_bw_task_13",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670325085612-0",
  },
  between_tasks_end: {
    ad_name: "win_banner_bw_task_14",
    type: [336, 280],
    ad_unit_div: "div-gpt-ad-1670325109670-0",
  },

  task_ad_popup: {
    ad_name: "win_inter_on_task_default",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1670236277913-0",
  },

  task_ad_popup_xs: {
    ad_name: "win_inter_on_task_default_xs",
    type: [300, 100],
    ad_unit_div: "div-gpt-ad-1682416743840-0",
  },

  win_task_complete1: {
    ad_name: "win_banner_task_completion_1",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670657546479-0",
  },
  win_task_complete2: {
    ad_name: "win_banner_task_completion_2",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670657793404-0",
  },
  win_task_complete3: {
    ad_name: "win_banner_task_completion_3",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670657829547-0",
  },
  win_task_complete4: {
    ad_name: "win_banner_task_completion_4",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670657859099-0",
  },
  win_task_complete5: {
    ad_name: "win_banner_task_completion_5",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670657895308-0",
  },
  win_task_complete6: {
    ad_name: "win_banner_task_completion_6",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670657929177-0",
  },
  win_task_complete7: {
    ad_name: "win_banner_task_completion_7",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670657973887-0",
  },
  win_task_complete8: {
    ad_name: "win_banner_task_completion_8",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670658008387-0",
  },
  win_task_complete9: {
    ad_name: "win_banner_task_completion_9",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670658048079-0",
  },
  win_task_complete10: {
    ad_name: "win_banner_task_completion_10",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670658079191-0",
  },
  win_task_complete11: {
    ad_name: "win_banner_task_completion_11",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670658120150-0",
  },
  win_task_complete12: {
    ad_name: "win_banner_task_completion_12",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670658174730-0",
  },
  win_task_complete13: {
    ad_name: "win_banner_task_completion_13",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670658208791-0",
  },
  win_task_complete14: {
    ad_name: "win_banner_task_completion_14",
    type: [200, 200],
    ad_unit_div: "div-gpt-ad-1670658244216-0",
  },

  /* AD UNITS XS */
  between_tasks_xs: {
    ad_name: "win_banner_between_tasks_default_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643127981-0",
  },
  between_tasks1_xs: {
    ad_name: "win_banner_bw_task_1_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643197356-0",
  },
  between_tasks2_xs: {
    ad_name: "win_banner_bw_task_2_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643249028-0",
  },
  between_tasks3_xs: {
    ad_name: "win_banner_bw_task_3_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643289096-0",
  },
  between_tasks4_xs: {
    ad_name: "win_banner_bw_task_4_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643360854-0",
  },
  between_tasks5_xs: {
    ad_name: "win_banner_bw_task_5_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643396747-0",
  },
  between_tasks6_xs: {
    ad_name: "win_banner_bw_task_6_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643424858-0",
  },
  between_tasks7_xs: {
    ad_name: "win_banner_bw_task_7_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643452452-0",
  },
  between_tasks8_xs: {
    ad_name: "win_banner_bw_task_8_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643479401-0",
  },
  between_tasks9_xs: {
    ad_name: "win_banner_bw_task_9_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643502356-0",
  },
  between_tasks10_xs: {
    ad_name: "win_banner_bw_task_10_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643525682-0",
  },
  between_tasks11_xs: {
    ad_name: "win_banner_bw_task_11_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1670236236423-0",
  },
  between_tasks12_xs: {
    ad_name: "win_banner_bw_task_12_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643550345-0",
  },
  between_tasks13_xs: {
    ad_name: "win_banner_bw_task_13_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643579604-0",
  },
  between_tasks_end_xs: {
    ad_name: "win_banner_bw_task_14_xs",
    type: [300, 250],
    ad_unit_div: "div-gpt-ad-1679643603994-0",
  },
};
