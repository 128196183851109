import { createSlice } from "@reduxjs/toolkit";

const initState = {
  index: {},
  running: {},
  participated: {},
  completed: {},
  won: {},
};

const giveawaySlice = createSlice({
  name: "giveaways",
  initialState: initState,
  reducers: {
    initIndexGiveaways: (state, action) => {
      state.index = { ...state.index, ...action.payload };
    },
    initRunningGiveaways: (state, action) => {
      state.running = { ...state.running, ...action.payload };
    },
    initParticipatedGiveaways: (state, action) => {
      state.participated = { ...state.participated, ...action.payload };
    },
    initWonGiveaways: (state, action) => {
      state.won = { ...state.won, ...action.payload };
    },
    updateParticipationTask: (state, action) => {
      const { giveaway_id, updated_tasks } = action.payload;
      state.participated[giveaway_id].tasks = [...updated_tasks];
    },
    updateParticipationData: (state, action) => {
      const { giveaway_id, data } = action.payload;
      state.participated[giveaway_id] = {
        ...state.participated[giveaway_id],
        ...data,
      };
    },
    clearParticipatedData: () => {
      return { ...initState };
    },
    updateGiveawayStatus: (state, action) => {
      const { current_state, new_state, giveawayId } = action.payload;
      state.index[giveawayId].status = new_state;
      state[current_state][giveawayId].status = new_state;
      state[new_state] = {
        ...state[new_state],
        [giveawayId]: { ...state[current_state][giveawayId] },
      };
      let giveaways = { ...state[current_state] };
      delete giveaways[giveawayId];
      state[current_state] = { ...giveaways };
    },
    updateGiveawayAccountUsed: (state, action) => {
      const { giveaway_id, account, platform } = action.payload;
      state.participated[giveaway_id].accounts_used[platform] = { ...account };
    },
  },
});

export const {
  initIndexGiveaways,
  initRunningGiveaways,
  initParticipatedGiveaways,
  initWonGiveaways,
  updateParticipationTask,
  updateParticipationData,
  clearParticipatedData,
  updateGiveawayStatus,
  updateGiveawayAccountUsed,
} = giveawaySlice.actions;
export default giveawaySlice.reducer;
