import Lottie from "lottie-react";
import React from "react";
import loader from "../../assets/img/oc_loader.png";
import loader_white from "../../assets/animations/loader_white.json.json";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { isParticipantBlocked } from "../../services/fb";
import { updateBlocked } from "../../store/userSlice";

function Loader() {
  const userId = useSelector((state) => state.user.id);
  const dispatch = useDispatch();

  useEffect(() => {
    //console.log("checking block");
    if (userId !== null)
      isParticipantBlocked(userId).then((isBlocked) => {
        dispatch(updateBlocked(isBlocked));
      });
  });
  return (
    <div id="OCLoader" className="Loader">
      <div className="Loader-img Loader-animation">
        <Lottie animationData={loader_white} />
        {/* <img height="150px" src={loader} alt="loader svg" /> */}
      </div>
    </div>
  );
}

export default Loader;
