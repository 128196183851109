const index_routes = {
  dashboard: "dashboard",
  giveaways: "giveaways",
};

const giveaway_routes = {
  participate: "participate",
  participated: "participated",
  completed: "completed",
  ended: "ended",
};

const dashboard_routes = {
  home: "home",
  settings: "settings",
  giveaways: "giveaways",
  profile_form: "profile_form",
};

export { giveaway_routes, dashboard_routes, index_routes };
