import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import giveawayReducer from "./giveawaySlice";
import { env } from "../meta";
const rootReducer = {
  user: userReducer,
  giveaways: giveawayReducer,
};

export const store = configureStore({
  reducer: { ...rootReducer },
  devTools: env === "development" ? true : false,
});
