import { createSlice } from "@reduxjs/toolkit";

const initState = {
  id: null,
  auth: null,
  profile: {
    email: "",
    mobile: "",
    first_name: "",
    last_name: "",
    addresses: {},
  },
  is_blocked: false,
  connected_accounts: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    login: (state, action) => {
      return { ...state, ...action.payload };
    },
    logout: () => {
      return {
        id: null,
        auth: null,
        profile: {
          email: "",
          mobile: "",
          first_name: "",
          last_name: "",
          addresses: {},
        },
      };
    },
    updateProfile: (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    },
    updateAddress: (state, action) => {
      const { address_tag } = action.payload;
      state.profile.addresses[address_tag] = { ...action.payload };
    },
    updateBlocked: (state, action) => {
      state.is_blocked = action.payload;
    },
    addSocialAccount: (state, action) => {
      const { platform, data } = action.payload;
      state.connected_accounts = {
        ...state.connected_accounts,
        [platform]: { ...data },
      };
    },
  },
});

export const {
  login,
  logout,
  updateProfile,
  updateAddress,
  updateBlocked,
  addSocialAccount,
} = userSlice.actions;
export default userSlice.reducer;
