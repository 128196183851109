const setSessionUserId = (id) => {
  sessionStorage.setItem("userId", id);
};

const removeSessionUserId = (id) => {
  sessionStorage.removeItem("userId");
};

const getSessionAuth = () => {
  return sessionStorage.getItem(
    "firebase:authUser:AIzaSyAImpAj_7CH3r4smu2EZBZmDuqWOW5hz2Y:[DEFAULT]"
  );
};

const getSessionUserId = () => {
  return sessionStorage.getItem("userId");
};

export const setSessionUniqueVisit = (data) => {
  localStorage.setItem("visit_tracked", JSON.stringify(data));
};

export const updateSessionUniqueVisit = (data) => {
  const session_unique_visit = getSessionUniqueVisit();
  localStorage.setItem(
    "visit_tracked",
    JSON.stringify({ ...session_unique_visit, ...data })
  );
};

export const getSessionUniqueVisit = () => {
  return JSON.parse(localStorage.getItem("visit_tracked"));
};

export const getSessionGiveawaysTracked = () => {
  const gta = JSON.parse(localStorage.getItem("gta"));
  if (gta === null) return [];
  return gta;
};

export const setSessionGiveawaysTracked = (giveaway) => {
  const stored_giveaways = getSessionGiveawaysTracked();
  if (stored_giveaways === null) localStorage.setItem("gta", [giveaway]);
  else localStorage.setItem("gta", [...stored_giveaways, giveaway]);
};

export const removeVisitTracked = () => {
  localStorage.removeItem("visit_tracked");
};

export const getGiveawayTrackWithRef = () => {
  const gta_r = localStorage.getItem("gta_r");
  if (gta_r === null) return {};
  return JSON.parse(gta_r);
};

export const setGiveawayTrackWithRef = ({ ref_data }) => {
  const gta_r = getGiveawayTrackWithRef();
  gta_r[ref_data.giveaway_id] = ref_data;
  const data_stringified = JSON.stringify(gta_r);
  localStorage.setItem("gta_r", data_stringified);
};

export {
  setSessionUserId,
  removeSessionUserId,
  getSessionAuth,
  getSessionUserId,
};
