import { useEffect, useState } from "react";
//import GoogleSignIn from "../login/GoogleSignIn";
//import Loader from "./Loader";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import cross_icon_grey from "../../assets/img/svg/cross_icon_grey.svg";
import cross_icon_red from "../../assets/img/svg/cross_icon_red.svg";
import dotLoader from "../../assets/animations/dotloader.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../store/userSlice";
import WhatsAppButtonGreenLarge from "../../assets/img/svg/WhatsAppButtonGreenLarge.svg";
import { auth } from "../../services/fb";
import { clearParticipatedData } from "../../store/giveawaySlice";
import { useNavigate } from "react-router-dom";

//import useBackListener from "./useBackListener";

function Popup({
  styles,
  classes,
  children,
  setPopup,
  close_button = true,
  disablePopState = false,
}) {
  useEffect(() => {
    if (close_button) {
      try {
        const content_container =
          document.getElementsByClassName("Popup-header")[0];
        const cross_button = document.createElement("span");
        cross_button.setAttribute("id", "PopupCloseButton");
        cross_button.setAttribute(
          "class",
          "span-icon-30 span-align-right cursor-pointer"
        );
        cross_button.addEventListener("click", () => {
          setPopup(false);
        });
        const button_image = document.createElement("img");
        button_image.setAttribute("src", cross_icon_grey);
        button_image.setAttribute("alt", "closer");

        cross_button.appendChild(button_image);
        content_container?.appendChild(cross_button);
      } catch (err) {
        console.log(err.message);
      }
    }
  });

  useEffect(() => () => {
    if (close_button) {
      const cross_button = document.getElementById("PopupCloseButton");
      cross_button?.remove();
    }
  });

  return (
    <div id="PopupContainer" className={`Popup-container`}>
      <div
        onClick={() => {
          if (!disablePopState) setPopup(false);
        }}
        className="Popup-close-overlay"
      ></div>
      {close_button === "outside" ? (
        <div className="Popup-header-empty">
          <button className="Close-popup-round">
            <img src={cross_icon_red} alt="Close Popup" />
          </button>{" "}
        </div>
      ) : null}
      <div
        id="PopupBox"
        style={{ ...styles }}
        className={`Popup-box ${classes?.popup_box}`}
      >
        {children}
      </div>
    </div>
  );
}

function OtpPopup({
  phone,
  setCodePopupState,
  setOtpCounter,
  otpCounter,
  confirmationResult,
  setSignInState,
  retryOTP,
}) {
  const [loading, setLoading] = useState(false);
  const codeSchema = yup.object().shape({
    code: yup.string().required("This is required"),
  });
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({ resolver: yupResolver(codeSchema) });

  const [isResent, setResent] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    confirmationResult
      .confirm(data.code)
      .then((result) => {
        //console.log("FROM OTP POPUP", result);
        setSignInState(result);
      })
      .catch((error) => {
        console.log("Error from OTP Popup", error.message);
        setError("code", { message: "Incorrect OTP" });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (otpCounter !== 0)
      setTimeout(() => {
        setOtpCounter(otpCounter - 1);
      }, 1000);
  }, [otpCounter]);

  return (
    <>
      <div className="Popup-header">
        <p>Enter OTP</p>
        <button onClick={() => setCodePopupState(false)}>
          <img src={cross_icon_grey} alt="close popup" />
        </button>
      </div>
      <div className="Popup-content">
        <p>
          We have sent your OTP to{" "}
          <span className="span-text-bold accent-text">{phone}</span>.
        </p>
        {!isResent && otpCounter !== 0 ? (
          <p>Resend in {otpCounter} seconds</p>
        ) : (
          <p
            id="sign-in-resubmit"
            onClick={() => {
              setResent(true);
              setLoading(false);
              retryOTP();
            }}
            className="text-link"
          >
            Resend OTP
          </p>
        )}
        <div className="Confirmation-code-input-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              onChange={() => clearErrors()}
              className="Connect-code-input"
              type="text"
              placeholder="Enter OTP here"
              {...register("code")}
            />
            {errors.code ? <p>{errors.code && errors.code.message}</p> : null}{" "}
            <br />
            {loading ? (
              <button type="button" className="btn-full btn-primary">
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={dotLoader}
                  alt="loading"
                />
              </button>
            ) : (
              <button type="submit" className="btn-full btn-primary">
                Submit
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

function ParticipationComplete({ suggested_giveaways_list }) {
  return (
    <Popup>
      <div className="Popup-header-congrats Popup-header-background-green-right">
        <h4>Congratulations!</h4>
        <p>All Tasks Completed</p>
      </div>
      <div className="Popup-content">
        <p>Suggested Giveaways for you</p>
        <ul className="Suggested-giveaway-list-container">
          {suggested_giveaways_list.map((giveaway) => {
            return (
              <li className="Suggested-giveaway-list-card" key={giveaway.id}>
                <span>
                  <img src={giveaway.banner_image} alt="Giveaway Banner" />
                </span>
                {giveaway.title}
              </li>
            );
          })}
        </ul>
      </div>
    </Popup>
  );
}

function SupportPopupBlocked() {
  const dispatch = useDispatch();
  const authFirebase = auth;
  const navigate = useNavigate();
  return (
    <Popup close_button={false}>
      <div className="Popup-header">
        <p> Account Blocked</p>{" "}
      </div>
      <div className="Popup-content">
        <p>Oops! It looks like your account has been blocked.</p>
        <p>
          Connect with us at{" "}
          <span className="text-bold">support@socialpi.ai</span>
        </p>
        <button className="btn-full btn-tertiary">
          <a href="mailto:support@socialpi.ai">Send an E-mail</a>
        </button>
        <br />
        <button
          onClick={() => {
            sessionStorage.clear();
            dispatch(logout());
            dispatch(clearParticipatedData());
            authFirebase.signOut();
            navigate("/");
          }}
          className="btn-full btn-primary"
        >
          Logout
        </button>
      </div>
    </Popup>
  );
}

function SupportPopup({ setPopupState }) {
  return (
    <Popup setPopup={setPopupState}>
      <div className="Popup-header">
        <p> Contact Support</p>{" "}
        {/*  <button onClick={() => setPopupState(false)}>
          <img src={cross_icon_grey} alt="close popup" />
        </button> */}
      </div>
      <div className="Popup-content">
        <p>
          Hi! Have any doubts or queries? Feel free to connect with us at
          <br />
          Email: <span className="text-bold">support@socialpi.ai</span>
          <br />
          Whatsapp: <span className="text-bold">+918178369930</span>
        </p>
        <div className="text-align-center margin-top-small">
          <button>
            <a
              href="https://wa.me/918178369930"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: "14rem", objectFit: "contain" }}
                src={WhatsAppButtonGreenLarge}
                alt="Chat on whatsapp"
              />
            </a>
          </button>
          <button
            style={{ width: "14rem", height: "3rem" }}
            className="btn-tertiary fs-1-1"
          >
            <a href="mailto:support@socialpi.ai">Send an E-mail</a>
          </button>
        </div>
      </div>
    </Popup>
  );
}

const CongratulationsPopup = ({ first_name, last_name, reward, setPopup }) => {
  const navigate = useNavigate();
  return (
    <Popup
      close_button={false}
      styles={{ backgroundColor: "rgba(0,0,0,0)" }}
      setPopup={setPopup}
    >
      <div
        onClick={() => setPopup(false)}
        className="Popup-header Winner-popup-header"
      ></div>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "0.6rem",
          textAlign: "center",
        }}
        className="Popup-content"
      >
        <br />

        <p style={{ color: "#3d0dc6" }} className="fs-1">
          <span className="text-bold"> Congratulations!</span>
          <br />
          {first_name} {last_name} on winning
        </p>
        <br />
        <div>
          <img
            className="square-image-small"
            style={{ objectFit: "cover", borderRadius: "0.4rem" }}
            src={reward.image}
            alt="reward"
          />
          <p
            style={{
              width: "90%",
              margin: "auto",
              fontWeight: "500",
            }}
          >
            <span className="text-bold fs-1 margin-bottom-small">
              {reward.title}
            </span>
            {/*    <span className="text-bold">Details:</span> {reward.details} */}
          </p>

          <p className="accent-text">
            {reward.status !== "distributed"
              ? "The Giveaway Creator has not distributed the reward yet. Keep checking this page for updates!"
              : "Reward has been distributed!."}
            {reward.status !== "distributed" ? null : (
              <button
                style={{ marginTop: "24px" }}
                onClick={() => navigate("/dashboard/rewards")}
                className="btn-full btn-primary"
              >
                Go To Rewards
              </button>
            )}
          </p>
        </div>
      </div>
    </Popup>
  );
};

export {
  ParticipationComplete,
  OtpPopup,
  SupportPopupBlocked,
  SupportPopup,
  CongratulationsPopup,
};
export default Popup;
