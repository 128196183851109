import "./App.css";
import "./assets/styles/global.css";
import "./assets/styles/Common.css";
import "./assets/styles/Dashboard.css";

import { lazy, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { dashboard_routes, giveaway_routes, index_routes } from "./routes";
import {
  getSessionUserId,
  setSessionUserId,
} from "./services/sessionFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  auth,
  doesParticipantExist,
  fetchGiveawaysWon,
  fetchParticipant,
  fetchParticipatedGiveaways,
  fetchRunningGiveaways,
} from "./services/fb";
import { login } from "./store/userSlice";
import {
  initIndexGiveaways,
  initParticipatedGiveaways,
  initRunningGiveaways,
  initWonGiveaways,
} from "./store/giveawaySlice";
import Loader from "./components/common/Loader";
//import ProfileForm from "./pages/registration/ProfileForm";
//import GiveawayCompleted from "./pages/giveaways/GiveawayCompleted";
//import GiveawayEnded from "./pages/giveaways/GiveawayEnded";
//import AuthHandlerYoutube from "./pages/account_connection/AuthHandlerYoutube";
import UserBlocked from "./pages/UserBlocked";
import { onAuthStateChanged } from "firebase/auth";
//import GiveawayIndex from "./pages/giveaways/GiveawayNavIndex";
//import RewardManagement from "./pages/giveaways/RewardManagement";
//import AuthHandlerTwitter from "./pages/account_connection/AuthHandlerTwitter";
import ErrorFallback from "./components/common/errors/ErrorFallback";
import WebViewRedirection from "./components/common/WebViewRedirection";
import { updateLastLogin } from "./services/fbAuth";
//import AddressManagement from "./pages/profile/AddressManagement";

const AppLayout = lazy(() => import("./pages/layouts/AppLayout"));
const GiveawayLayout = lazy(() => import("./pages/layouts/GiveawayLayout"));
const DashboardLayout = lazy(() => import("./pages/layouts/DashboardLayout"));

const GiveawayIndex = lazy(() => import("./pages/giveaways/GiveawayNavIndex"));
const GiveawayEnded = lazy(() => import("./pages/giveaways/GiveawayEnded"));
const GiveawayCompleted = lazy(() =>
  import("./pages/giveaways/GiveawayCompleted")
);

const Login = lazy(() => import("./pages/Login"));
const ProfileForm = lazy(() => import("./pages/registration/ProfileForm"));

const AuthHandlerTwitter = lazy(() =>
  import("./pages/account_connection/AuthHandlerTwitter")
);
const AuthHandlerYoutube = lazy(() =>
  import("./pages/account_connection/AuthHandlerYoutube")
);

const GiveawayParticpate = lazy(() =>
  import("./pages/giveaways/GiveawayParticipate")
);

const DashboardHome = lazy(() => import("./pages/dashboard/DashboardHome"));
const AddressManagement = lazy(() =>
  import("./pages/profile/AddressManagement")
);
const RewardManagement = lazy(() =>
  import("./pages/giveaways/RewardManagement")
);

document.documentElement.style.setProperty(
  "--vh",
  `${window.innerHeight * 0.01}px`
);
window.addEventListener("resize", () => {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );
});

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userId = getSessionUserId();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const authFirebase = auth;

  const dispatchData = () => {
    var status = {
      user: false,
      runningGiveaways: false,
      participatedGiveaways: false,
      wonGiveaways: false,
    };
    return new Promise(async (resolve) => {
      if (userId === null || userId === undefined) {
        setLoading(true);
        await fetchRunningGiveaways().then((fetchedRunningGiveaways) => {
          dispatch(initRunningGiveaways(fetchedRunningGiveaways));
          dispatch(initIndexGiveaways(fetchedRunningGiveaways));
          return resolve(true);
        });
      }
      if (userId !== null) {
        setLoading(true);
        await fetchParticipant(userId).then((fetchedParticipant) => {
          if (fetchedParticipant === false) {
            sessionStorage.clear();
            return resolve(false);
          }
          dispatch(login(fetchedParticipant));
          status.user = true;
          /*   if (
            fetchedParticipant.profile.mobile === "" ||
            fetchedParticipant.profile.email === ""
          )
            navigate(
              `${index_routes.dashboard + "/" + dashboard_routes.profile_form}`
            ); */
        });
        await fetchRunningGiveaways().then((fetchedRunningGiveaways) => {
          dispatch(initRunningGiveaways(fetchedRunningGiveaways));
          dispatch(initIndexGiveaways(fetchedRunningGiveaways));
          status.runningGiveaways = true;
        });
        await fetchParticipatedGiveaways(userId).then(
          (fetchedParticipatedGiveaways) => {
            dispatch(
              initParticipatedGiveaways(
                fetchedParticipatedGiveaways.participated
              )
            );
            dispatch(initIndexGiveaways(fetchedParticipatedGiveaways.index));
            status.participatedGiveaways = true;
          }
        );
        await fetchGiveawaysWon(userId).then((fetchedWonGiveaways) => {
          dispatch(initWonGiveaways(fetchedWonGiveaways));
          status.wonGiveaways = true;
        });
        if (
          status.runningGiveaways &&
          status.user &&
          status.participatedGiveaways &&
          status.wonGiveaways
        ) {
          return resolve(true);
        }
      }
      return resolve(false);
    });
  };

  const [isWebView, setWebView] = useState(false);
  const [isApple, setApple] = useState(false);
  const [isThirdParty, setThirdParty] = useState(false);
  useEffect(() => {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent;

    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;

    const isIos =
      /ip(ad|hone|od)/.test(normalizedUserAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    const isAndroid = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const is3rdParty =
      /instagram/.test(normalizedUserAgent) || /fbav/.test(normalizedUserAgent);
    const isVersion = /version/.test(normalizedUserAgent);
    //console.log(normalizedUserAgent);

    const webview =
      (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
      (isIos && !standalone && !isSafari);

    if (isIos) setApple(true);
    if (webview) setWebView(true);
    if (!is3rdParty) setThirdParty(!is3rdParty);
    //console.log(isApple, isThirdParty, isWebView);
  }, [isWebView, isThirdParty, isApple]);

  useEffect(() => {
    onAuthStateChanged(authFirebase, (user) => {
      if (user === null) {
        sessionStorage.clear();
        //navigate("/", { replace: true });
      } else if (user !== null) {
        //console.log(user, "From auth state");
        const provider =
          user.providerData[0].providerId === "google.com" ? "email" : "mobile";
        const id =
          provider === "email"
            ? user.providerData[0].email
            : user.providerData[0].phoneNumber;
        doesParticipantExist(provider, id).then((result) => {
          //console.log(result, "From auth effect");
          if (result.exists) setSessionUserId(result.data.id);
        });
      }
    });
  }, [authFirebase]);

  useEffect(() => {
    //console.log("In effect");
    dispatchData().then((resolved) => {
      //console.log(resolved);
      if (resolved) {
        setLoading(false);
      } else {
        navigate("/");
        setLoading(false);
      }
    });
  }, [userId]);

  if (user.is_blocked) return <UserBlocked />;
  if (loading)
    return (
      <div style={{ height: "100vh" }} className="flex-row flex-align-center">
        <Loader />
      </div>
    );
  else if (isWebView)
    return (
      <WebViewRedirection
        is_IOS={isApple}
        is_third_party_wv={isThirdParty}
        //setWebView={setWebView}
      />
    );
  else
    return (
      <Routes>
        <Route path="/" element={<AppLayout />}>
          {/*   <Route
            path="test"
            element={
              <WebViewRedirection
                is_IOS={isApple}
                is_third_party_wv={isThirdParty}
              />
            }
          /> */}

          <Route index element={<Login />} />
          <Route
            path={"auth_handler/youtube"}
            element={<AuthHandlerYoutube />}
          />
          <Route
            path={"auth_handler/twitter"}
            element={<AuthHandlerTwitter />}
          />

          <Route path={index_routes.dashboard} element={<DashboardLayout />}>
            <Route path="profile" element={<AddressManagement />} />
            <Route path="rewards" element={<RewardManagement />} />
            <Route path={dashboard_routes.home} element={<DashboardHome />} />

            <Route
              path={dashboard_routes.profile_form}
              element={<ProfileForm />}
            />
          </Route>
          <Route path={index_routes.giveaways} element={<GiveawayLayout />}>
            <Route path="redirect/:id" element={<GiveawayIndex />} />

            <Route
              path={giveaway_routes.participate + "/:id"}
              element={<GiveawayParticpate />}
            />

            {/*   <Route path={"p/:id"} element={<GiveawayParticpate />} /> */}

            <Route
              path={giveaway_routes.ended + "/:id"}
              element={<GiveawayEnded />}
            />
            <Route
              path={giveaway_routes.completed + "/:id"}
              element={<GiveawayCompleted />}
            />
          </Route>
        </Route>
      </Routes>
    );
}

export default App;
